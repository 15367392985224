import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import GoogleIcon from "./primary/Icon";

function BuildFuture() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        position="relative"
        bg="rgba(0, 0, 0, 0.6)"
        color="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={onOpen}
        paddingY={{ base: 10, md: 120 }}
      >
        <Flex
          alignItems="center"
          gap={4}
          direction={{ base: "column", md: "row" }}
        >
          <Text fontSize={{ base: "xl", md: "3xl" }}>COMMUNITY BUILDING</Text>
          <GoogleIcon
          fontSize={{base:26,md:48}}
            name="play_arrow"
            width={{base:"30px",md:"50px"}}
            height={{base:"30px",md:"50px"}}
            borderRadius="50%"
            border="2px solid"
            _hover={{
              borderColor: "lp_grey.400",
              color: "lp_grey.400",
            }}
          />
          <Text fontSize={{ base: "xl", md: "3xl" }}>WITH LIVETSPUSSEL</Text>
        </Flex>
      </Box>
      <VideoModal
        isOpen={isOpen}
        onClose={onClose}
        url="https://www.youtube.com/embed/XaHOQ9DmffE"
      />
    </>
  );
}

const VideoModal = ({
  isOpen,
  onClose,
  url,
}: {
  isOpen: boolean;
  onClose: () => void;
  url: string;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth={700}>
        <ModalBody padding={0}>
          <iframe
            title="education"
            id="youtubeVideo"
            width="100%"
            height="415"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BuildFuture;
