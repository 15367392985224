import { SystemProps, extendTheme } from "@chakra-ui/react";

type Colors = {
  lp_grey: {
    [key: number]: string;
  };
};

export interface CustomLinkProps extends SystemProps {
  theme: {
    colors: Colors
  };
}

const theme = extendTheme({
  colors: {
    lp_grey: {
      50: "#f7f7f7",
      100: "#eeeeee",
      200: "#cfcfcf",
      300: "#b1b1b1",
      400: "#9e9e9e",
      500: "#55565b",
      600: "#434343",
      700: "#383838",
      800: "#303030",
      900: "#1c1c1c",
    } 
  } satisfies Colors,
  fonts: {
    lato: `'lato'`,
  },
}) 

export default theme;
