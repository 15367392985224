import { Avatar, Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import classes from "./TeamMember.module.scss";

type TeamMemberProps = {
  picture: string;
  name: string;
  role: string;
  bioText: string;
};

const TeamMember = ({ bioText, name, picture, role }: TeamMemberProps) => (
  <Flex
    direction={{ base: "column", md: "row" }}
    justify="space-between"
    color="lp_grey.400"
    rowGap={{ base: 4, md: 10 }}
  >
    <Flex
      direction={{ base: "row", md: "column" }}
      align={{ base: "center", md: "start" }}
      className={classes.Avatar}
      gap={{ base: 4, md: 8 }}
    >
      <Avatar size={{ base: "lg", md: "2xl" }} name={name} src={picture} />

      <Stack className={classes.title}>
        <Heading color="lp_grey.600" fontWeight="Bold" fontSize="lg">
          {name}
        </Heading>
        <Text fontSize="sm">{role}</Text>
      </Stack>
    </Flex>

    <Box className={classes.bio} maxWidth={{ base: "100%", md: "70%" }}>
      <Text>{bioText}</Text>
    </Box>
  </Flex>
);

export default TeamMember;
