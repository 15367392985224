import { SimpleGrid, Spacer } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { OurTitle } from "../primary/OurTitle";
import Section from "../primary/Section";
import { ServiceItem } from "./ServiceItem";
import { servicesList } from "./ServicesData";

const Services = () => {
  return (
    <Section id="services-expand">
      <Spacer h={{ base: 10, md: 20 }} />
      <OurTitle title="Services" />
      <SimpleGrid
        columns={[1, 1, 2, 2, 3]}
        columnGap={6}
        rowGap={12}
        margin="0 auto"
        padding={{ base: "32px 0", md: "64px 0" }}
        color="lp_grey.600"
      >
        {servicesList.map((service, index) => (
          <motion.div
            key={service.slug}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.1 * index }}
            viewport={{ once: true }}
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <ServiceItem {...service} />
            </motion.div>
          </motion.div>
        ))}
      </SimpleGrid>
    </Section>
  );
};
export default Services;
