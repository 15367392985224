import { Box, BoxProps } from "@chakra-ui/react";

type GoogleIconProps = {
  name: string;
} & BoxProps;

const GoogleIcon = ({ name, fontSize, ...rest }: GoogleIconProps) => {
  return (
    <Box fontSize={fontSize ?? 48} className="material-symbols-sharp" {...rest}>
      {name}
    </Box>
  );
};

export default GoogleIcon;
