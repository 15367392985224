import { Flex, Heading, Spacer, Stack } from "@chakra-ui/react";
import Section from "../primary/Section";
import NewsItem from "./NewsItem";
import { newsList } from "./NewsData";
import { motion } from "framer-motion";

const News = () => {
  return (
    <Section id="news-expand">
      <Spacer h={{ base: 8, md: 16 }} bg="white" />
      <Flex
        height={{ base: 10, md: 20 }}
        gap={2}
        color={"lp_grey.500"}
        paddingLeft={6}
        borderLeftStyle={"solid"}
        borderLeftWidth={2}
        borderLeftColor={"lp_grey.600"}
        alignItems={"center"}
      >
        <Heading fontSize={["2xl", "3xl"]} fontWeight="thin">
          LATEST
        </Heading>
        <Heading fontSize={["2xl", "3xl"]}>NEWS</Heading>
      </Flex>
      <Spacer h={{ base: 8, md: 16 }} bg="white" />
      <Stack gap={8}>
        {newsList.map((news) => (
          <motion.div
            key={news.slug}
            whileHover={{
              scale: 1.04,
            }}
          >
            <NewsItem item={news} />
          </motion.div>
        ))}
      </Stack>
      <Spacer h={{ base: 8, md: 16 }} bg="white" />
    </Section>
  );
};

export default News;
