import { Flex, Image, Text } from "@chakra-ui/react";

const Logo = () => (
  <Flex alignItems="center" gap={2} color="lp_grey.400">
    <Image
      w={10}
      h={10}
      src="/images/logo.svg"
      alt="Foundation logo symbolizing an integrated, holistic educational approach for a peaceful world civilization"
    />
    <Text fontSize="large" fontWeight="bolder" fontFamily="lato">
      LIVETS PUSSEL
    </Text>
  </Flex>
);

export default Logo;
