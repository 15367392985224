export type NewsImage = { src: string; alt: string };

export type NewsModel = {
  slug: string;
  title: string;
  author: string;
  publishDate: Date;
  content: string;
  summary: string;
  images?: NewsImage[];
};

export const newsList: NewsModel[] = [
  {
    slug: "Inquiry-Based Learning (IBL) vs. Project-Based Learning (PBL): A Comparative Analysis",
    title: "Inquiry-Based Learning (IBL) vs. Project-Based Learning (PBL)",
    author: "Porooshat Sabeti",
    publishDate: new Date(2024, 7, 19),
    content: `
In the realm of education, two pedagogical approaches have gained significant attention for their ability to foster active learning and critical thinking: **Inquiry-Based Learning (IBL)** and **Project-Based Learning (PBL)**. Both strategies encourage student engagement, but they differ in focus, structure, and the specific skills they aim to develop. Understanding these differences is crucial for educators who wish to implement the most effective teaching methods for their learners.

&nbsp; 
 
**Inquiry-Based Learning (IBL): Emphasizing Curiosity and Exploration**

&nbsp; 

**Inquiry-Based Learning (IBL)** is a student-centered approach that places a strong emphasis on exploration and critical thinking. In IBL, learning is driven by the questions and curiosities of the students. Instead of being provided with information directly, students are encouraged to ask questions, conduct investigations, and draw conclusions based on their findings. This approach mirrors the scientific method, where inquiry begins with a question or problem and proceeds through hypothesis, investigation, analysis, and conclusion.

&nbsp; 

IBL is particularly effective in fostering a deep conceptual understanding of the subject matter. Because students are actively engaged in the process of discovery, they tend to develop a stronger grasp of the content. Moreover, IBL promotes higher-order thinking skills, such as analysis, synthesis, and evaluation, as students must not only find answers but also critically assess the information they encounter.

&nbsp; 

The flexibility of IBL makes it applicable across various disciplines, from the sciences to the humanities. Whether students are investigating a historical event, exploring a mathematical concept, or conducting a scientific experiment, the process of inquiry allows them to engage deeply with the material. This approach is especially beneficial in environments where fostering curiosity and a love of learning is a primary goal.

&nbsp; 

**Project-Based Learning (PBL): Fostering Real-World Skills**

&nbsp; 

**Project-Based Learning (PBL)**, on the other hand, is a structured approach that emphasizes the application of knowledge to real-world scenarios. In PBL, students work on a project over an extended period, which often involves solving a complex problem or answering a challenging question. The project typically results in a tangible product, such as a presentation, report, or physical model, that can be shared with others.

&nbsp; 

PBL is designed to develop a range of practical skills, including collaboration, communication, problem-solving, and project management. By working on projects, students learn to apply knowledge from multiple disciplines, often integrating concepts from science, technology, engineering, arts, and mathematics (STEAM). This interdisciplinary approach reflects the complexity of real-world problems and prepares students for the challenges they will face in their future careers.

&nbsp; 

Moreover, PBL provides students with a sense of ownership and accountability. Since the projects are often connected to real-world issues, students are motivated to produce high-quality work that has a meaningful impact. The structured nature of PBL, with specific milestones and deliverables, also helps students develop time management and organizational skills.

&nbsp; 

**Comparing IBL and PBL: Which Approach is Better?**

&nbsp; 

Both IBL and PBL have their unique strengths and can be highly effective in different educational contexts. The choice between these approaches largely depends on the specific goals of the educator and the needs of the students.

&nbsp; 

**Inquiry-Based Learning** is ideal for situations where the primary goal is to develop deep conceptual understanding and foster a spirit of curiosity. By allowing students to explore questions and problems that interest them, IBL encourages self-directed learning and critical thinking. It is particularly effective in disciplines where inquiry and exploration are central, such as the sciences and the humanities.

&nbsp; 

**Project-Based Learning**, on the other hand, is better suited for environments where the emphasis is on applying knowledge to real-world situations. PBL helps students develop practical skills that are essential in the workforce, such as collaboration, communication, and problem-solving. It is particularly effective in STEAM education, where the integration of multiple disciplines and the creation of tangible products are key.

&nbsp; 

**Integrating IBL and PBL: A Hybrid Approach**

&nbsp; 

While IBL and PBL are distinct approaches, they are not mutually exclusive. In fact, many educators find that integrating elements of both can lead to a more comprehensive learning experience. For example, a project-based learning unit could begin with an inquiry phase, where students explore the topic and develop questions that guide their project. This hybrid approach allows students to benefit from the strengths of both methods: the deep exploration and critical thinking of IBL and the practical application and real-world relevance of PBL.

&nbsp; 

**Conclusion**

&nbsp; 

In the debate between **Inquiry-Based Learning (IBL)** and **Project-Based Learning (PBL)**, there is no clear winner. Both approaches offer valuable opportunities for student engagement and learning, but they cater to different educational goals. **IBL** is more effective for fostering curiosity, deep understanding, and critical thinking, while **PBL** excels in developing practical skills and applying knowledge in real-world contexts. Educators should consider their specific objectives and the needs of their students when choosing between these methods—or better yet, combine the strengths of both to create a rich and dynamic learning environment.
`,
    summary:
      'Inquiry-Based Learning (IBL) emphasizes student-driven exploration and critical thinking, fostering deep conceptual understanding, while Project-Based Learning (PBL) focuses on applying knowledge to real-world problems, developing practical skills. Integrating both approaches can provide a comprehensive learning experience that combines exploration with practical application.',
  },
  {
    slug: "learning-from-youth-project",
    title: "Learning from Youth Project",
    author: "Porooshat Sabeti",
    publishDate: new Date(2023, 5, 10),
    summary:
      'Discover how the "Learning from Youth Project" fosters ownership, problem-solving, and community involvement among adolescents through Project-Based Learning. Authored by Porooshat Sabeti',
    content: `Several noteworthy elements in the [youth's project](${process.env.REACT_APP_BASE_URL}/news/youth-project) and its associated activities are detailed below to provide a better understanding of the Project-Based learning (PBL) method:

* First, there is a strong sense of ownership and belonging among the youth towards this project. Since all activities are planned through group consultations and by themselves, they are eager to take on responsibilities without any pressure from the mentor.

* Second, the youth's learning approach is notable. They engage in learning through study, consultation, and action based on their inner needs, without the rigidity of traditional teaching methods. They eagerly search for knowledge. For example, in making puppets, they applied various skills and knowledge, from mathematics to art, thinking about how to use their academic learning practically and experimenting until they succeeded.

* Third, these activities foster a sense of growth and belief in their unique abilities.

* Fourth, a new identity is formed through these activities. As individuals transition into adolescence, they seek to build a personal identity. Traditional systems often push students towards a competitive and self-serving identity, leading to irresponsibility and apathy. Service projects, however, offer students the chance to focus on community needs and work collectively to improve their situation, shaping an identity based on service, cooperation, and compassion.

* Fifth, these activities strengthen "observational skills" and "problem-solving abilities". Identifying community needs for the project enhanced their observational skills, crucial for problem-solving. In their initial sessions, they encountered what seemed like insurmountable obstacles, which discouraged them. However, over time, they learned the principles of problem-solving and how to progressively achieve their goals.

* Sixth, they learn essential principles such as "consultation". 

* Seventh, the project provides opportunities to enhance "communication skills" and "linguistic abilities". Through those festivals and seminars, they had many chances to strengthen these skills. For example, one youth wrote a story about the importance of protecting nature, and another prepared a speech on the importance of early childhood education for parents. They also enhanced their linguistic skills through consultations and discussions. Sometimes conflicts arose, and resolving these issues taught them the importance of choosing words carefully for effective communication.

* Another important point is the role of parents in these activities. For instance, a mother involved in the youth group's project encouraged them during moments of despair and even helped them complete the puppets. This example shows how such activities can transform all community members into active agents of social change. When a project starts, not only students but all community members have the chance to contribute to building an ideal society. The teacher's perspective on the importance of unity and coordination among community members plays a crucial role in achieving this collective participation.`,
  },
  {
    slug: "gardening-day",
    title: "Gardening Day",
    author: "Porooshat Sabeti",
    publishDate: new Date(2023, 3, 7),
    content: `Several teachers who work with children aged 5-8, after consulting about the school's condition, concluded that the school needed beautification. Therefore, they shared the idea of a gardening project with their students. It was decided that each student would bring seeds or plant cuttings from home for the gardening day. They also used discarded containers and car tires as flower pots. In this way, each student engaged in the project according to their abilities and interests. Some moved and filled the pots with soil, while the younger ones carefully planted the seeds.
  
  After that day, based on a mutually agreed schedule, students took turns watering the seeds and plants. Through this project, which lasted several months, the students practically learned cooperation, environmental awareness, creativity, patience, responsibility, organization, and many other virtues and skills.`,
    summary:
      'Explore the "Gardening Day" initiative where young students beautify their school with recycled containers and learn essential virtues like cooperation and responsibility. Authored by Porooshat Sabeti',
    images: [
      {
        src: "/images/gardening-day/school-garden-project-display.jpeg",
        alt: "Gardening Day project display with plants in recycled containers on a blue used desk at a school",
      },
      {
        src: "/images/gardening-day/gardening-day-flower-pot-display.jpeg",
        alt: "Gardening Day project with a flower pot containing red flowers placed on a cinder block at a school",
      },
      {
        src: "/images/gardening-day/gardening-day-plants-in-recycled-containers.jpeg",
        alt: "Gardening Day project with various plants growing in recycled containers lined up at a school",
      },
      {
        src: "/images/gardening-day/gardening-day-recycled-pots-display.jpeg",
        alt: "Gardening Day project with various flowers planted in recycled containers at a school",
      },
      {
        src: "/images/gardening-day/gardening-day-school-garden-display.jpeg",
        alt: "Gardening Day display with plants in recycled containers on a blue used stand at a school",
      },
      {
        src: "/images/gardening-day/gardening-day-students-working-with-soil.jpeg",
        alt: "Gardening Day project with students in uniforms working together with a pile of soil at a school",
      },
      {
        src: "/images/gardening-day/gardening-day-tire-planter.jpeg",
        alt: "Gardening Day project with young plants growing inside a recycled tire used as a planter at a school",
      },
      {
        src: "/images/gardening-day/gardening-day-children-planting-tire.jpeg",
        alt: "Gardening Day project with children planting seeds in a recycled tire planter at a school",
      },
      {
        src: "/images/gardening-day/gardening-day-children-planting-tires.jpeg",
        alt: "Gardening Day project with children filling recycled tire with soil at a school",
      },
      {
        src: "/images/gardening-day/gardening-day-children-sorting-soil.jpeg",
        alt: "Gardening Day project with children sorting soil for planting at a school",
      },
    ],
  },
  {
    slug: "youth-project",
    title: "Youth Project",
    author: "Porooshat Sabeti",
    publishDate: new Date(2023, 2, 25),
    content: `A group of young people aged 14-17 decided to identify the needs of their community to define a service project. After some study and consultation, they discovered that the need for educating children aged 4-6 in their area was not recognized. Additionally, there were limited facilities for this age group, and economic difficulties prevented parents from enrolling their children in kindergartens. Therefore, the group members decided to prepare themselves to start free classes for children aged 4-6. However, they lacked sufficient skills and knowledge in teaching children, so they needed to acquire these before proceeding. Encouraged by their mentor, they began to study. They soon realized that they would not gain adequate skills until they practically implemented the knowledge gained from their studies. Hence, they decided to organize a series of activities to enhance their skills through interaction with children.

&nbsp;

Their first activity was a festival held on Nature Day, coinciding with the start of the school year. The youth organized various segments based on the needs of the children and their own interests and abilities, including plays, poetry, sports, and painting. This festival provided them with an opportunity to learn principles such as consultation, responsibility, and planning. After the festival, they met again to review and continue the learning cycle, reflecting on the strengths of their work and supporting each other. They also shared their learnings and planned future activities inspired by these consultations.

One of the key lessons they learned was:
> We faced many obstacles before the festival, causing work to halt at times. For instance, two members responsible for making puppets for the play couldn't find a suitable pattern, and the rest of the group was unaware of this problem, halting the work temporarily. Finally, when the issue was announced, through consultation and more research, we found a new pattern and created beautiful puppets. We learned that in future activities, if we encounter a barrier that we cannot overcome alone, we should share the issue with the group sooner.`,
    summary:
      'Learn about the "Youth Project" where teenagers develop skills and knowledge to provide free education to young children, overcoming challenges through community efforts. Authored by Porooshat Sabeti',
  },

];
