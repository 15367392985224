import classes from "./Home.module.scss";
import { Spacer, Text } from "@chakra-ui/react";
import { Heading, Box, Container } from "@chakra-ui/react";

type StyledTextProps = {
  children: string;
};

const StyledText = ({ children }: StyledTextProps) => {
  return (
    <Text color="lp_grey.500" as="p" fontSize="md" fontWeight="normal">
      {children}
    </Text>
  );
};

const Home = () => {
  return (
    <Container
      className={classes.homeText}
      id="home-expand"
      zIndex={1}
      position="relative"
      maxW="100%"
      centerContent
      backgroundColor="lp_grey.100"
      color="lp_grey.500"
    >
      <Box maxW={1200}>
        <div className={classes.title}>
          <Heading
            className={classes.livets}
            fontSize={{ base: "4xl", md: "6xl" }}
            fontWeight="thin"
          >
            LIVETS
          </Heading>
          <Heading
            className={classes.pussel}
            fontSize={{ base: "4xl", md: "6xl" }}
          >
            PUSSEL
          </Heading>
        </div>
        <StyledText>
          The current educational system often fails to meet humanity's needs
          and aspirations. Many global issues, including social and economic
          problems, can be traced back to the system's insufficient quality.
          Schools present numerous subjects, each claiming supreme importance,
          yet lacking apparent connections with one another. Consequently, young
          people often experience their lives and studies as a collection of
          jigsaw puzzle pieces that they cannot fit together into a coherent
          picture. It is therefore predictable that an incoherent and
          inconsistent life does not evoke a sense of agency and motivation. To
          address this fragmentation, we have started a foundation focused on
          creating an integrated, holistic worldview. This approach helps young
          people form their own visions of a fulfilling life.
        </StyledText>
        <Spacer h={4} />
        <StyledText>
          We believe the ultimate goal of education is to build a peaceful world
          civilization. Our foundation strives to spearhead a transformative
          approach to education. Our courses focus on empowering individuals to
          become active agents of their own learning, where their potentials are
          realized. By nurturing these individuals, we foster capacity-building,
          enabling them to act as protagonists in the continuous effort to apply
          knowledge for both individual and collective transformation. This
          holistic approach ensures that education is not merely about imparting
          information but about inspiring a generation of leaders dedicated to
          creating a just society as a flower-bed for human blossoming.
        </StyledText>
      </Box>
    </Container>
  );
};

export default Home;
