import { Box, Flex, HStack, Heading, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { NewsModel } from "./NewsData";
import { format } from "date-fns";

const NewsItem = ({ item }: { item: NewsModel }) => {
  const { publishDate, title, author, content, slug } = item;

  return (
    <Link to={`/news/${slug}`}>
      <Flex
        direction={{ base: "column", md: "row" }}
        rowGap={{ base: 4, md: 10 }}
        alignItems="center"
      >
        <Flex
          flex={1}
          maxWidth={{ base: "none", md: 470 }}
          alignItems={{ base: "end", md: "center" }}
          flexDir={{ base: "row-reverse", md: "row" }}
          justifyContent={{ base: "space-between" }}
          width={{ base: "100%", md: "auto" }}
        >
          <HStack width={{ base: "fit-content", md: 150 }}>
            <Text
              fontSize={{ base: "lg", md: "4xl" }}
              fontWeight="thin"
              color="lp_grey.400"
            >
              {format(publishDate, "d")}
            </Text>
            <Text
              paddingBottom={{ base: 0, md: 2 }}
              fontSize={{ base: "xs", md: "sm" }}
              color="lp_grey.400"
            >
              {format(publishDate, "MMMM")}
            </Text>
          </HStack>

          <Stack
            flex={1}
            maxWidth={320}
            paddingLeft={5}
            paddingY={{ base: 0, md: 4 }}
            borderLeft={1}
            borderStyle={"solid"}
            borderColor={"lp_grey.400"}
          >
            <Heading
              fontWeight="Bold"
              fontSize="lg"
              color={"lp_grey.500"}
              cursor="pointer"
            >
              {title}
            </Heading>
            <Text fontSize="sm" color="lp_grey.300">
              {author}
            </Text>
          </Stack>
        </Flex>

        <Box flex={1} alignItems={"center"}>
          <Text
            color={"lp_grey.400"}
            noOfLines={{ base: 3, md: 5 }}
            cursor="pointer"
            textAlign="justify"
            fontSize="sm"
          >
            {content}
          </Text>
        </Box>
      </Flex>
    </Link>
  );
};

export default NewsItem;
