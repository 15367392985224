import { MenuItem } from "./SubMenu";

export const menuItems: MenuItem[] = [
  {
    scrollTo: "home-expand",
    label: "HOME",
  },
  {
    scrollTo: "services-expand",
    label: "SERVICES",
  },
  {
    scrollTo: "aboutUs-expand",
    label: "OUR TEAM",
  },
  {
    scrollTo: "news-expand",
    label: "NEWS",
  },
  {
    scrollTo: "contact-expand",
    label: "CONTACT",
  },
];
