import {
  HStack,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Markdown } from "../primary/Markdown";
import { ServiceModel } from "./ServicesData";

export const ServiceItem = (props: ServiceModel) => {
  const { title, summary, icon, slug } = props;

  return (
    <Link to={`/service/${slug}`}>
      <VStack spacing={2} alignItems="start" cursor="pointer">
        <HStack spacing={2}>
          {icon}
          <Heading color="lp_grey.600" as="h3" fontSize="lg">
            {title}
          </Heading>
        </HStack>
        <Text fontSize="sm" color="lp_grey.400">
          <Markdown>{summary}</Markdown>{" "}
          <Text as="span" color="lp_grey.900">
            show more...
          </Text>
        </Text>
      </VStack>
    </Link>
  );
};
