import { Heading } from "@chakra-ui/react";
import classes from "./Hero.module.scss";

const Hero = () => {
  return (
    <>
      <header className={classes.header}>
        <img
          className={classes.image}
          src="/images/main-image.png"
          alt="livets pussel name header"
        ></img>
        <div className={classes.banner}>
          <Heading
            as="h1"
            fontSize={{ base: "2rem", md: "5rem" }}
            fontWeight={{ base: "bold", md: "lighter" }}
            color="white"
            marginBottom={{ base: "1rem", md: "2rem" }}
          >
            Livets Pussel
          </Heading>
          <Heading color="white" fontSize="1.3rem" fontWeight="lighter">
            Education | Sustainable Development
          </Heading>
        </div>
      </header>
    </>
  );
};
export default Hero;
