import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex
} from "@chakra-ui/react";
import { useState } from "react";
import GoogleIcon from "../primary/Icon";
import Logo from "./Logo";
import classes from "./Menu.module.scss";
import { menuItems } from "./MenuItems";
import SubMenu from "./SubMenu";

const Menu = () => {
  const [mobileDrawerOpen, setMobileMenuOpen] = useState(false);

  const onClose = () => setMobileMenuOpen(false);

  //TODO: this method has a performance issue, it is better to use useEffect and debounce
  window.addEventListener("resize", (event) => {
    if (
      event.target &&
      (event.target as Window).innerWidth > 1280 &&
      mobileDrawerOpen
    ) {
      setMobileMenuOpen(false);
    }
  });

  return (
    <nav className={classes.bar}>
      <Logo />
      {/* desktop */}
      <Flex
        className={classes.links}
        alignItems="center"
        columnGap={30}
        display={{ base: "none", xl: "flex" }}
      >
        {menuItems.map((item, index) => (
          <SubMenu key={index} item={item} onClose={onClose} />
        ))}

        {/* <GoogleIcon name="search" fontSize={40} color="lp_grey.400" /> */}
      </Flex>

      {/* hamburger menu */}
      <Button
        aria-label="Open Menu"
        size="lg"
        mr={2}
        onClick={() => setMobileMenuOpen(true)}
        display={{ base: "block", xl: "none" }}
        variant="link"
      >
        <GoogleIcon name="menu" fontSize={38} color="lp_grey.400" />
      </Button>

      {/* mobile content */}
      <Drawer
        isOpen={mobileDrawerOpen}
        placement="right"
        size="md"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>

          <DrawerBody>
            <Flex
              flexDirection="column"
              className={classes.links}
              display={{ base: "flex", xl: "none" }}
            >
              {/* <Input
                className={classes.searchInput}
                placeholder="Search"
                size="lg"
              />
              <Divider /> */}
              {menuItems.map((item, index) => (
                <SubMenu key={index} item={item} onClose={onClose} />
              ))}
            </Flex>
          </DrawerBody>

          <DrawerFooter
            backgroundColor="lp_grey.900"
            color="white"
            justifyContent="center"
          >
            copyright @LivetsPussel
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </nav>
  );
};

export default Menu;
