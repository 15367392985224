import { ReactNode } from "react";
import GoogleIcon from "../primary/Icon";

export type ServiceModel = {
  title: string;
  summary: string;
  description: string;
  slug: string;
  icon: ReactNode;
};

export const servicesList: ServiceModel[] = [
  {
    icon: <GoogleIcon name="communities" fontSize={48} color="lp_grey.600" />,
    title: "Community-Building In School",
    slug: "community-building-in-school",
    summary: `How do we motivate our students?\n
How do we help them reach holistic health and wellbeing?\n
How do we engage the families of our students?\n
`,
    description: `
How do we motivate our students?\n
How do we help them reach holistic health and wellbeing?\n
How do we engage the families of our students?\n
&nbsp;\n
These, and many other topical questions about key aspects of the school's mandate, lead us to think about our attitudes and perspectives towards education, teaching methods, and practical strategies. Each school and class can be seen as a potential practicing ground for the creation of an ideal human community, a miniature society where all those involved learn how they can contribute to their society. In this approach, new patterns of thinking, acting, and relating will be created. A society where every person can be regarded as a mine of valuable gems, in the form of human virtues, talents, and interests, that need to be discovered, polished, and brought to serve the common good. By facilitating meaningful projects in schools, students are encouraged to find solutions to problems based on their innate curiosity. They learn how to apply knowledge using a scientific approach to improve their communities. This method significantly enhances their passion for attending school and makes studying meaningful, transforming them into active agents of their own learning.
&nbsp;\n
We offer a one-term course for teachers and school leaders which is carried out in the form of a transformative learning circle, involving theoretical perspectives, practical experimentation, and collective reflection and planning.
`,
  },
  {
    icon: <GoogleIcon name="tactic" fontSize={48} color="lp_grey.600" />,
    title: "Project-Based Learning in Action",
    slug: "project-based-learning-in-action",
    summary: `Teachers, particularly those who have previously taken part in the "Community Building in School" course, might be interested in a step-by-step guide to engaging their students in a developmental project.`,
    description: `Teachers, particularly those who have previously taken part in the "Community Building in School" course, might be interested in a step-by-step guide to engaging their students in a developmental project. To facilitate this, a 3-month workshop will be organized. During this workshop, teachers will initiate a project, with their students, under the guidance of a facilitator, and will familiarize themselves with the various steps involved. Regular weekly meetings will also be scheduled for consulting and guiding teachers, with the aim of enhancing the quality of project implementation.`,
  },
  {
    icon: <GoogleIcon name="history_edu" fontSize={48} color="lp_grey.600" />,
    title: "Educational Discourses/Seminars",
    slug: "educational-discourses-seminars",
    summary: `We can arrange discourses/seminars in your school or educational setting tailored to the needs, concerns, and aspirations of administrators, teachers, and parents. These discussions can cover a broad range...`,
    description: `We can arrange discourses/seminars in your school or educational setting tailored to the needs, concerns, and aspirations of administrators, teachers, and parents. These discussions can cover a broad range of topics. Here are some examples:\n
*	Supporting young people exposed to gangs
*	Promoting nonviolent communication among youth
*	Addressing suicide: prevention and treatment
`,
  },
  {
    icon: <GoogleIcon name="trophy" fontSize={48} color="lp_grey.600" />,
    title: "Global Heroes All Together",
    slug: "global-heroes-all-together",
    summary: `Heroism is generally considered something associated with few exceptional individuals. Today's world situation requires many fundamental transformations and calls for an entire generation of heroes.`,
    description: `Heroism is generally considered something associated with few exceptional individuals. Today's world situation requires many fundamental transformations and calls for an entire generation of heroes. All young people have the potential of living up to this historical mission, in collaboration with each other. This is a one-year integrated high school course that comes with ready lesson plans and materials. The first term consists of conceptual studies, while the second term is dedicated to carrying out and learning from local service projects. We offer a course package for the students, as well as a preparatory training and ongoing coaching for teachers.`,
  },
  {
    icon: <GoogleIcon name="routine" fontSize={48} color="lp_grey.600" />,
    title: "Everyday Life Matters",
    slug: "everyday-life-matters",
    summary: `Big and important issues, such as democracy, human rights, equality, and justice, have their roots in the ways we ordinary people think, act, and relate to each other in countless everyday life situations.`,
    description: `Big and important issues, such as democracy, human rights, equality, and justice, have their roots in the ways we ordinary people think, act, and relate to each other in countless everyday life situations. For children and youth, the spaces where they can learn about realizing their highest human potential, in relation to their fellow-humans, are the school and the home. Usually, these two blame each other a lot. We have prepared a program with two integrated tracks to be carried out simultaneously, at school and at home. The program consists of stories, discussions, and various exercises. It is planned for students in years 7-9. We provide the teachers, not only with materials, but also with a preparatory training and ongoing coaching. This program creates a new way of collaboration between the school and the students' homes.`,
  },
  {
    icon: (
      <GoogleIcon
        name="settings_accessibility"
        fontSize={48}
        color="lp_grey.600"
      />
    ),
    title: "Youth Leadership Education",
    slug: "youth-leadership-education",
    summary: `Leadership is the capability to see the best potential in other people and in situations, and to be able to help realize that potential. Many young people have already developed attitudes and skills of such leadership.`,
    description: `Leadership is the capability to see the best potential in other people and in situations, and to be able to help realize that potential. Many young people have already developed attitudes and skills of such leadership. By inviting them and offering them various opportunities to develop and exercise their leadership, schools can better succeed in realizing the potential of each student and in releasing the collective constructive powers residing in the student body. This course will guide the participating students to know themselves better, to gain self-mastery and inner leadership of their own lives, and to help others become conscious of and direct their capabilities towards serving the common good. Adjusted versions of the course are available for ages 10-18.`,
  },
];
