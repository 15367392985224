import { Flex, Image } from "@chakra-ui/react";
import { useRef, useState } from "react";
import GoogleIcon from "./primary/Icon";
import { NewsImage } from "./blog/NewsData";

export const Gallery = ({ images }: { images: NewsImage[] }) => {
  const imageRefs = useRef<Array<HTMLImageElement | null>>([]);
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollToImage = (index: number) => {
    scrollViewRef.current?.scrollTo({
      left: imageRefs.current[index]?.offsetLeft,
      behavior: "smooth",
    });
  };

  const findNewIndex = (type: "prev" | "next") => {
    let newIndex =
      Math.max(type === "next" ? currentIndex + 1 : currentIndex - 1, -1) %
      images.length;

    const currentImage = imageRefs.current[currentIndex];
    let nextImage = imageRefs.current[newIndex];
    const scrollViewWidth = scrollViewRef.current?.clientWidth;
    let filledArea =
      currentImage?.offsetWidth ?? 0 + (nextImage?.offsetWidth ?? 0);

    while (
      nextImage &&
      scrollViewWidth &&
      filledArea + nextImage.offsetWidth <= scrollViewWidth
    ) {
      newIndex =
        (type === "next" ? newIndex + 1 : newIndex - 1) % images.length;
      filledArea += nextImage.offsetWidth;
      nextImage = imageRefs.current[newIndex];
    }

    return newIndex;
  };

  const handlePrevClick = () => {
    let newIndex = findNewIndex("prev");

    if (newIndex === -1) {
      newIndex = images.length - 1;
    }

    setCurrentIndex(newIndex);
    scrollToImage(newIndex);
  };

  const handleNextClick = () => {
    const newIndex = findNewIndex("next");

    setCurrentIndex(newIndex);
    scrollToImage(newIndex);
  };

  return (
    <Flex overflow="visible" position="relative">
      <GoogleIcon
        display={{ base: "none", sm: "block" }}
        name="arrow_back_ios"
        fontSize={40}
        position="absolute"
        alignSelf="center"
        left={-10}
        cursor="pointer"
        onClick={handlePrevClick}
      />
      <GoogleIcon
        display={{ base: "none", sm: "block" }}
        name="arrow_forward_ios"
        fontSize={40}
        position="absolute"
        alignSelf="center"
        right={-12}
        cursor="pointer"
        onClick={handleNextClick}
      />
      <Flex
        gap={2}
        overflow="scroll"
        scrollSnapType="x mandatory"
        ref={scrollViewRef}
        position="relative"
      >
        {images.map((img, index) => (
          <Image
            ref={(el) => (imageRefs.current[index] = el)}
            scrollSnapAlign="start"
            height={{ base: 220, sm: 300 }}
            objectFit="contain"
            key={index}
            src={img.src}
            alt={img.alt}
          />
        ))}
      </Flex>
    </Flex>
  );
};
