import BuildFuture from "../components/BuildFuture";
import Home from "../components/Home";
import Info from "../components/Info";
import Subscribe from "../components/Subscribe";
import OurTeam from "../components/about-us/OurTeam";
import News from "../components/blog/News";
import FloatingCircleDev from "../components/dev-tools/FloatingCircle";
import Hero from "../components/hero/Hero";
import Menu from "../components/menu/Menu";
import Services from "../components/services/Services";

const MainPage = () => {
  return (
    <>
      <Hero />
      <Menu />
      <Home />
      <Services />
      <BuildFuture />
      <OurTeam />
      <Subscribe />
      <News />
      <Info />
      {process.env.REACT_APP_STAGE === "development" && <FloatingCircleDev />}
    </>
  );
};

export default MainPage;
