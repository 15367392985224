import { Spacer, VStack } from "@chakra-ui/react";
import { OurTitle } from "../primary/OurTitle";
import Section from "../primary/Section";
import TeamMember from "./TeamMember";

const OurTeam = () => {
  return (
    <Section id="aboutUs-expand">
      <Spacer h={{ base: 8, md: 16 }} bg="white" />

      <OurTitle title="team" />
      <Spacer h={{ base: 8, md: 16 }} bg="white" />

      <VStack spacing={12}>
        <TeamMember
          picture="/images/kamran.jpg"
          name="Kamran"
          role="International Educational Consultant"
          bioText="I have worked with educational issues in many different roles, such as a teacher, teacher educator, educator of educational leaders, school developer, and educational consultant, for over 40 years in different parts of the world, but mainly in Sweden. I have retired from Mälardalen University where I worked for a long time as a lecturer in pedagogy but still continue with various projects and research. Why am I involved? I believe that we as humanity have come to a unique historical point of development where for the first time it is practically possible to create a world where all humanity experiences its oneness and can live together in peace, justice, and prosperity. In order for this fine potential to be realized, however, major transformative changes are needed both within us humans and in our social structures. Diversity must express itself within the framework of basic unity and traditional dichotomies and divisions must give way to coherent wholeness with complementary aspects. The world's youth are best equipped to lead these transformations. They just need to get the right kind of leadership and training. My lifelong passion has been and is to work with the creation of prerequisites so that the world's young generations would be prepared and have the chance to carry out their historic mission."
        />

        <TeamMember
          picture="/images/porooshat.jpg"
          name="Porooshat"
          role="Educational Consultant"
          bioText={`For many years, I've dedicated my energy and knowledge to education, with a specific focus on project-based learning (PBL). I believe that consultation, study and action are essential elements of the learning cycle. The absence of any one of these elements can disrupt this cycle. So, I believe that the most effective opportunity to facilitate this cycle is through the implementation of projects. why am i involved? My foremost motivation stems from a profound belief in moral responsibility towards society. I hold the conviction that true progress is only achieved when it uplifts every member of society. This belief is grounded in the interconnectedness of human fate, where challenges faced in one part of the world invariably impact others. I am convinced that authentic education is the cornerstone of freedom and sustainable development, which empowers individuals and leads to community building. 
          In addition to training teachers and initiating student projects, I am actively studying the impact of space on student learning. My focus is not only on transferring knowledge but also on shaping society and nurturing a global civilization. The term "Family Learning Center" is a more fitting name for this educational environment than simply "school".`}
        />
      </VStack>
      <Spacer h={{ base: 8, md: 16 }} bg="white" />
    </Section>
  );
};

export default OurTeam;
