type Response<T> = {
  data?: T;
  error?: string;
  message?: string[];
};

export const createSubscription = async (email: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/subscription`,
    {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const result = (await response.json()) as Response<string>;

  return result;
};
