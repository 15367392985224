import { Box, Container } from "@chakra-ui/react";
import { ReactNode } from "react";

type SectionProps = {
  children: ReactNode;
  color?: "white" | "grey";
  id?: string;
};

const Section = ({ children, color, id }: SectionProps) => (
  <Box id={id} position="relative" zIndex={1} backgroundColor={color === "grey" ? "lp_grey.100" : "white"}>
    <Container maxW="6xl">{children}</Container>
  </Box>
);

export default Section;
