import { Box, BoxProps, styled } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";

const MarkdownWrapper = styled(Box, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    blockquote: {
      borderLeft: "4px solid #f0f0f0",
      padding: "0.5rem 1rem",
      margin: "1rem 0",
    },
    ul: {
      paddingLeft: "2rem",

      li: {
        marginTop: "0.5rem",
        textAlign: "left",
      },
    },
    a: {
      color: "lp_blue.900",
      textDecoration: "underline",
    },
  },
});

type MarkdownProps = Omit<BoxProps, "children"> & { children: string };

export const Markdown = ({ children, ...rest }: MarkdownProps) => {
  return (
    <MarkdownWrapper {...rest}>
      <ReactMarkdown children={children} />
    </MarkdownWrapper>
  );
};
