import {
  Box,
  Center,
  Link as ChakraLink,
  Heading,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import Logo from "../components/menu/Logo";
import GoogleIcon from "../components/primary/Icon";
import { Markdown } from "../components/primary/Markdown";
import ScrollToTop from "../components/primary/ScrollToTop";
import { servicesList } from "../components/services/ServicesData";
import { scrollToSection } from "../helpers/scroll";

const ServicePage = () => {
  const { slug } = useParams<{ slug: string }>();
  const service = servicesList.find((n) => n.slug === slug);

  if (!service) {
    return (
      <Center>
        <Heading>SERVICE NOT FOUND</Heading>;
      </Center>
    );
  }

  return (
    <>
      <Helmet>
        <title>{service.title}</title>
        <meta name="description" content={service.summary} />
      </Helmet>
      <ScrollToTop />
      <Box>
        <Box
          padding={4}
          top={0}
          zIndex={10}
          background="white"
          position="sticky"
          boxShadow="0px 5px 25px 0px rgba(0, 0, 0, 0.1)"
        >
          <Logo />
        </Box>

        <Spacer h={{ base: 4, md: 8 }} />

        <Center>
          <Stack padding={4} maxWidth={800} width="100%">
            <ChakraLink
              as={Link}
              to="/"
              display="flex"
              alignItems="center"
              _hover={{ textDecoration: "none" }}
              color="lp_grey.500"
              onClick={() =>
                setTimeout(() => {
                  scrollToSection("services-expand", false);
                }, 0)
              }
            >
              <GoogleIcon name="arrow_back_ios" fontSize={18} />
              <Text fontSize="sm">Back to Home</Text>
            </ChakraLink>

            <Spacer h={8} />
            <Heading as="h1" size="md" color="lp_grey.600">
              {service.title.toLocaleUpperCase()}
            </Heading>

            <Markdown>{service.description}</Markdown>
          </Stack>
        </Center>
      </Box>
    </>
  );
};

export default ServicePage;
