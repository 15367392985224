import classes from "./Info.module.scss";
import Section from "./primary/Section";
import { VStack, Text, Heading, Flex, Spacer } from "@chakra-ui/react";
import { ReactNode } from "react";
import GoogleIcon from "./primary/Icon";

type InfoItemProps = {
  title: string;
  url: string | ReactNode;
  icon: ReactNode;
};

const InfoItem = (props: InfoItemProps) => {
  const { icon, title, url } = props;

  return (
    <Flex
      gap={7}
      alignItems="center"
      width={{ base: "100%", md: "50%", lg: "fit-content" }}
    >
      {icon}
      <VStack alignItems="start" className={classes.info}>
        <Heading fontFamily="lato" fontWeight="Bold" fontSize="md">
          {title}
        </Heading>
        <Text fontSize="xs">{url}</Text>
      </VStack>
    </Flex>
  );
};

const Info = () => {
  return (
    <Section id="contact-expand" color="grey">
      <Spacer h={{ base: 8, md: 16 }} />
      <Flex
        id="contact-expand"
        flexWrap="wrap"
        maxWidth={970}
        justifyContent="space-between"
        color="lp_grey.500"
      >
        <InfoItem
          title="LINKEDIN"
          url={
            <a href="https://linkedin.com/company/livetspussel">
              https://linkedin.com/company/livetspussel
            </a>
          }
          icon={<GoogleIcon name="share" color="lp_grey.400" />}
        />

        <InfoItem
          title="E-MAIL"
          url={<a href="mailto:INFO@LIVETSPUSSEL.SE">INFO@LIVETSPUSSEL.SE</a>}
          icon={<GoogleIcon name="mail" color="lp_grey.400" />}
        />

        <InfoItem
          title="CALL US"
          url={<a href="tel:+46700274348">+46-700274348</a>}
          icon={<GoogleIcon name="smartphone" color="lp_grey.400" />}
        />
      </Flex>
      <Spacer h={{ base: 8, md: 16 }} />
    </Section>
  );
};

export default Info;
