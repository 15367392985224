import { ChakraProvider } from "@chakra-ui/react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import theme from "./helpers/theme";
import ColorsShowcase from "./pages/ColorsPage";
import MainPage from "./pages/MainPage";
import NewsPage from "./pages/NewsPage";
import ServicePage from "./pages/ServicePage";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/colors" element={<ColorsShowcase />} />
          <Route path="/news/:slug" element={<NewsPage />} />
          <Route path="/service/:slug" element={<ServicePage />} />
          <Route path="/" element={<MainPage />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
