import { Link, Text, useTheme } from "@chakra-ui/react";
import { scrollToSection } from "../../helpers/scroll";

export type MenuItem = {
  scrollTo: string;
  label: string;
};

type SubMenuProps = {
  item: MenuItem;
  onClose: () => void;
};

const SubMenu = (props: SubMenuProps) => {
  const { item, onClose } = props;
  const theme = useTheme();

  return (
    <Link
      color="lp_grey.400"
      _hover={{
        textDecoration: "none",
        boxShadow: `0 2px 0 ${theme.colors.lp_grey[400]}`,
      }}
      onClick={() => {
        scrollToSection(item.scrollTo, true);
        onClose();
      }}
    >
      <Text color="lp_grey.400">{item.label}</Text>
    </Link>
  );
};

export default SubMenu;
