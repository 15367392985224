import { Flex, Heading } from "@chakra-ui/react";

export const OurTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      height={{ base: 10, md: 20 }}
      gap={2}
      color={"lp_grey.500"}
      paddingLeft={6}
      borderLeftStyle={"solid"}
      borderLeftWidth={2}
      borderLeftColor={"lp_grey.600"}
      alignItems={"center"}
    >
      <Heading fontSize={["2xl", "3xl"]} fontWeight="light">
        OUR
      </Heading>
      <Heading fontSize={["2xl", "3xl"]}>{title.toUpperCase()}</Heading>
    </Flex>
  );
};
