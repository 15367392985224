import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { createSubscription } from "../apis/subscriptionApis";
import Section from "./primary/Section";
import Markdown from "react-markdown";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const toast = useToast();

  const subscribe = async () => {
    if (email === "") {
      setError("Email is required");
      return;
    }

    setIsLoading(true);

    const { data, message } = await createSubscription(email);

    setIsLoading(false);

    if (message) {
      setError(message[0]);
      return;
    }

    toast({
      description: <Markdown>{data}</Markdown>,
      status: "success",
      duration: 10000,
      isClosable: true,
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError(undefined);
  };

  return (
    <Section color="grey">
      <Spacer h={{ base: 8, md: 16 }} />
      <Flex
        flexWrap="wrap"
        justifyContent={{ base: "left", md: "right" }}
        columnGap={20}
        rowGap={6}
      >
        <FormControl isInvalid={Boolean(error)} width={350}>
          <Input
            type="email"
            placeholder="Enter your email"
            variant="flushed"
            fontSize="large"
            focusBorderColor="greyLight"
            borderColor="greyLightShadow"
            onChange={onChange}
            value={email}
            disabled={isLoading}
          />
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
        <Button
          colorScheme="lp_grey"
          size="md"
          paddingY={5}
          paddingX={10}
          borderRadius={0}
          fontWeight={400}
          fontSize="small"
          onClick={subscribe}
          isLoading={isLoading}
          disabled={email === ""}
        >
          SUBSCRIBE
        </Button>
      </Flex>
      <Spacer h={{ base: 8, md: 16 }} />
    </Section>
  );
};

export default Subscribe;
