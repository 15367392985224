import { Box, SimpleGrid, Text } from "@chakra-ui/react";

const colors = [
  { name: "customGray.50", color: "#f7f7f7" },
  { name: "customGray.100", color: "#eeeeee" },
  { name: "customGray.200", color: "#cfcfcf" },
  { name: "customGray.300", color: "#b1b1b1" },
  { name: "customGray.400", color: "#9e9e9e" },
  { name: "customGray.500", color: "#55565b" },
  { name: "customGray.600", color: "#434343" },
  { name: "customGray.700", color: "#383838" },
  { name: "customGray.800", color: "#303030" },
  { name: "customGray.900", color: "#1c1c1c" },
];

const ColorsShowcase = () => {
  return (
    <Box p={5}>
      <Text fontSize="2xl" mb={5}>
        Custom Colors
      </Text>
      <SimpleGrid columns={[2, null, 5]} spacing="10px">
        {colors.map((color) => (
          <Box
            key={color.name}
            bg={color.color}
            p={5}
            color="white"
            textAlign="center"
          >
            {color.name}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ColorsShowcase;
